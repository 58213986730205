function p(t){var s,r,a,d,u,e,o;if(!t||!((s=t.nodes)!=null&&s.length))return;const c=Array.from(document.querySelectorAll("[data-key-feature-id]"));for(const l of c){const f=l.dataset.keyFeatureId,n=t.nodes.find(i=>(i==null?void 0:i.id)===f);n&&(l.classList.remove("is-loading"),l.innerHTML=`
      <span class="product-card__meta-icon">
        <img
          src="${((a=(r=n==null?void 0:n.fields)==null?void 0:r.find(i=>i.key==="icon"))==null?void 0:a.reference.image.url)??""}"
          alt="${((u=(d=n==null?void 0:n.fields)==null?void 0:d.find(i=>i.key==="title"))==null?void 0:u.value)??""}"
          width="16"
          height="16"
        />
      </span>
      <span
        class="product-card__meta-label text-s">
        ${((o=(e=n==null?void 0:n.fields)==null?void 0:e.find(i=>i.key==="title"))==null?void 0:o.value)??""}
      </span>
    `)}}function m(t){var s,r,a;if(!t||!((s=t.nodes)!=null&&s.length))return;const c=Array.from(document.querySelectorAll("[data-bundle-collection]"));for(const d of c){const u=d.dataset.bundleCollection,e=t.nodes.find(o=>(o==null?void 0:o.id)===u);if((e==null?void 0:e.__typename)!=="Collection")return;if(!(!e||!((a=(r=e==null?void 0:e.products)==null?void 0:r.nodes)!=null&&a.length))){d.innerHTML="";for(const o of e.products.nodes.slice(0,3))d.innerHTML+=`
        <div class="product-card__meta-feature product-card__meta-feature--bundle">
          <span class="product-card__meta-icon">
          ${window.themeVars.icons.plus}
          </span>
          <span
            class="product-card__meta-label text-s">
            ${o.title}
          </span>
        </div>
      `;e.products.nodes.length>3&&(d.innerHTML+=`
        <div class="product-card__meta-feature product-card__meta-feature--bundle">
          <span class="product-card__meta-icon">
          ${window.themeVars.icons.plus}
          </span>
          <span class="product-card__meta-label text-s">
            ${e.products.nodes.length-3} ${window.themeVars.strings.collection.productCard.more}
          </span>
        </div>
      `)}}}function g(){const t=Array.from(document.querySelectorAll("sc-comparison-table"));if(!t.length)return;const c=`
    .view-more-button, .product .button {
      background-color: var(--color-brand-secondary);
      font-size: var(--font-size-sm);
      font-weight: var(--weight-book);
      border: none;
      border-radius: var(--spacing-l);
    }

    .attribute-name, .group-name {
      font-size: var(--spacing-s);
    }

    .attribute-value {
      font-size: var(--spacing-sm);
      font-weight: var(--weight-book);
    }

    @media(max-width: 863px) {
      .product__title {
        font-size: var(--font-size-sm);
      }

      .product__price {
       font-size: var(--font-size-sm);
       font-weight: var(--weight-book);
      }

      .rating-count {
        font-size: var(--spacing-xs);
      }
    }
  `;for(const s of t){const r=s.shadowRoot;if(r){const a=document.createElement("style");a.textContent=c,r.appendChild(a)}}}function w(t,c=!1){const{cartDeliveryEstimatedShipping:s,cartDeliveryDateEstimate:r}=window.themeVars.strings.cart,a=new Date(t).getTime(),d=new Date().getTime(),u=a-d,e=Math.round(Math.floor(u/(1e3*60*60*24))/7),o=e<=1?e:e-1,l=e+1,f=`${s}: ${o} - ${l} ${window.themeVars.strings.cart.weeks}`;if(c)return l<1?r:`${f} ${window.themeVars.strings.cart.weeks}`;if(u>0){const n=`${s}: ${r}`;return e<1?n:f}}export{g as a,p as b,w as s,m as u};
